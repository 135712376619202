import React from "react";
// import banner1 from "../../images/banner1.jpeg";
// import banner3 from "../../images/mission.jpg";
import uc from "../../images/underconstruction.png";
const WhoWeServe = () => {
  return (
    <div className="">
      <div className="w-full h-full flex items-center justify-center">
        <img src={uc} alt="" className="object-cover" />
      </div>
    </div>
  );
};

export default WhoWeServe;
