// import React from "react";
// // import banner1 from "../../images/banner1.jpeg";
// import banner2 from "../../images/banner2.jpeg";
// import banner3 from "../../images/mission.jpg";
// import icon1 from "../../icons/home.png";
// import icon2 from "../../icons/antiseptic.png";
// import icon3 from "../../icons/development.png";
// import icon4 from "../../icons/earth.png";
// import icon5 from "../../icons/gender-equality.png";
// import icon6 from "../../icons/grocery.png";
// import icon7 from "../../icons/healthcare.png";
// import icon8 from "../../icons/inequality.png";
// import icon9 from "../../icons/infrastructure.png";
// import icon10 from "../../icons/mortarboard.png";
// import icon11 from "../../icons/renewable-energy.png";
// import icon12 from "../../icons/sustainable.png";
// const Mission = () => {
//   // Function to scroll to a specific section
//   const scrollToSection = (id) => {
//     const element = document.getElementById(id);
//     element.scrollIntoView({ behavior: "smooth" });
//   };

//   return (
//     <div className="w-full flex items-center justify-center flex-col">
//       <div className="w-full h-[350px]">
//         <img src={banner3} alt="" className="object-cover  w-full h-[350px]" />
//       </div>
//       <div className="w-full flex items-center justify-around h-[50px] uppercase">
//         <div
//           onClick={() => scrollToSection("problems-section")}
//           className="cursor-pointer text-[#171443]"
//         >
//           Problem
//         </div>
//         <div
//           onClick={() => scrollToSection("mission-section")}
//           className="cursor-pointer text-[#171443]"
//         >
//           Mission
//         </div>
//         <div
//           onClick={() => scrollToSection("approch-section")}
//           className="cursor-pointer text-[#171443]"
//         >
//           Approch
//         </div>
//       </div>
//       <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
//         <h1
//           id="problems-section"
//           className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
//         >
//           The Problems We face
//         </h1>
//         <p className="text-center text-[#171443] text-[16px]">
//           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus
//           perspiciatis nobis, numquam laboriosam voluptatem ipsam eum modi
//           deserunt blanditiis perferendis consequuntur a, culpa hic id aut
//           labore consequatur. Consequatur quam harum doloremque placeat sint sit
//           architecto voluptate sapiente atque nemo ex veniam natus, facilis
//           quidem blanditiis possimus similique. Nemo, vel. Dicta velit dolores
//           eveniet, qui consequuntur eaque optio reprehenderit nemo
//           necessitatibus, sunt saepe vel eum mollitia facere, incidunt alias
//           temporibus eius placeat. Maxime esse soluta excepturi fuga recusandae
//           perspiciatis officiis perferendis magnam beatae illo? Expedita,
//           soluta. Deserunt, deleniti! Quasi, dicta? Sunt aliquam sed tempora
//           harum reprehenderit ex repudiandae corporis et necessitatibus dolor
//           cumque odio facilis molestiae officiis unde est autem aut quod, odit,
//           dolorum similique vero! Nihil inventore ullam, sint illo doloribus nam
//           deleniti voluptatum aliquid perspiciatis pariatur saepe veritatis
//           tempore accusamus, non, vel fuga. Dolorum, sunt impedit perferendis
//           reprehenderit fugit quod quos. Eius, non enim. Impedit mollitia
//           deleniti laudantium!
//         </p>
//       </div>
//       <div className="w-[1100px] max-w-[1100px] mt-[50px] flex items-center justify-center overflow-x-scroll no-scrollbar gap-x-20">
//         <img src={icon1} alt="" width={50} />
//         <img src={icon2} alt="" width={50} />
//         <img src={icon3} alt="" width={50} />
//         <img src={icon4} alt="" width={50} />
//         <img src={icon5} alt="" width={50} />
//         <img src={icon6} alt="" width={50} />
//         <img src={icon7} alt="" width={50} />
//         <img src={icon8} alt="" width={50} />
//         <img src={icon9} alt="" width={50} />
//         <img src={icon10} alt="" width={50} />
//         <img src={icon11} alt="" width={50} />
//         <img src={icon12} alt="" width={50} />
//       </div>
//       <div className="w-full h-[2px] mb-[10px] bg-[#171443] my-[50px]"></div>
//       <div className="w-full max-w-[1100px] my-[50px] flex items-center justify-center flex-col">
//         <h1
//           id="mission-section"
//           className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
//         >
//           One Question Endures: How Do We Do It?
//         </h1>
//         <p className="text-center text-[#171443] text-[16px]">
//           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus
//           perspiciatis nobis, numquam laboriosam voluptatem ipsam eum modi
//           deserunt blanditiis perferendis consequuntur a, culpa hic id aut
//           labore consequatur. Consequatur quam harum doloremque placeat sint sit
//           architecto voluptate sapiente atque nemo ex veniam natus, facilis
//           quidem blanditiis possimus similique. Nemo, vel. Dicta velit dolores
//           eveniet, qui consequuntur eaque optio reprehenderit nemo
//           necessitatibus, sunt saepe vel eum mollitia facere, incidunt alias
//           temporibus eius placeat. Maxime esse soluta excepturi fuga recusandae
//           perspiciatis officiis perferendis magnam beatae illo? Expedita,
//           soluta. Deserunt.
//         </p>
//       </div>
//       <div className="w-full h-[350px]">
//         <img
//           src={banner2}
//           alt=""
//           className="object-contain  w-full h-[350px]"
//         />
//       </div>
//       <div className="w-full max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
//         <h1
//           id="approch-section"
//           className=" uppercase mb-[40px] text-[40px] font-semibold text-[#171443]"
//         >
//           Creating A Society Where It's Easy To Take Action
//         </h1>
//         <p className="text-center text-[#171443] text-[16px]">
//           Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus
//           perspiciatis nobis, numquam laboriosam voluptatem ipsam eum modi
//           deserunt blanditiis perferendis consequuntur a, culpa hic id aut
//           labore consequatur. perspiciatis nobis, numquam laboriosam voluptatem
//           ipsam eum modi deserunt blanditiis perferendis consequuntur a, culpa
//           hic id aut labore consequatur.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Mission;

import React from "react";
// import banner1 from "../../images/banner1.jpeg";
import banner2 from "../../images/banner2.jpeg";
import banner3 from "../../images/mission.jpg";
import icon1 from "../../icons/home.png";
import icon2 from "../../icons/antiseptic.png";
import icon3 from "../../icons/development.png";
import icon4 from "../../icons/earth.png";
import icon5 from "../../icons/gender-equality.png";
import icon6 from "../../icons/grocery.png";
import icon7 from "../../icons/healthcare.png";
import icon8 from "../../icons/inequality.png";
import icon9 from "../../icons/infrastructure.png";
import icon10 from "../../icons/mortarboard.png";
import icon11 from "../../icons/renewable-energy.png";
import icon12 from "../../icons/sustainable.png";

const Mission = () => {
  // Function to scroll to a specific section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="w-full flex items-center justify-center flex-col px-4 sm:px-8">
      <div className="w-full h-[350px]">
        <img
          src={banner3}
          alt="Mission Banner"
          className="object-cover w-full h-[350px] sm:h-[400px] md:h-[450px]"
        />
      </div>
      <div className="w-full flex items-center justify-around h-[50px] uppercase text-sm sm:text-base">
        <div
          onClick={() => scrollToSection("problems-section")}
          className="cursor-pointer text-[#171443]"
        >
          Problem
        </div>
        <div
          onClick={() => scrollToSection("mission-section")}
          className="cursor-pointer text-[#171443]"
        >
          Mission
        </div>
        <div
          onClick={() => scrollToSection("approch-section")}
          className="cursor-pointer text-[#171443]"
        >
          Approach
        </div>
      </div>
      <div className="w-full max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
        <h1
          id="problems-section"
          className="uppercase mb-[40px] text-[30px] sm:text-[40px] font-semibold text-[#171443] text-center"
        >
          The Problems We face
        </h1>
        <p className="text-center text-[#171443] text-sm sm:text-base">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus
          perspiciatis nobis, numquam laboriosam voluptatem ipsam eum modi
          deserunt blanditiis perferendis consequuntur a, culpa hic id aut
          labore consequatur. Consequatur quam harum doloremque placeat sint sit
          architecto voluptate sapiente atque nemo ex veniam natus, facilis
          quidem blanditiis possimus similique. Nemo, vel. Dicta velit dolores
          eveniet, qui consequuntur eaque optio reprehenderit nemo
          necessitatibus, sunt saepe vel eum mollitia facere, incidunt alias
          temporibus eius placeat. Maxime esse soluta excepturi fuga recusandae
          perspiciatis officiis perferendis magnam beatae illo? Expedita,
          soluta. Deserunt, deleniti!
        </p>
      </div>
      <div className="w-full max-w-[1100px] mt-[50px] flex items-center justify-center overflow-x-auto no-scrollbar gap-x-10 sm:gap-x-16 md:gap-x-20">
        <img src={icon1} alt="Home Icon" width={50} />
        <img src={icon2} alt="Antiseptic Icon" width={50} />
        <img src={icon3} alt="Development Icon" width={50} />
        <img src={icon4} alt="Earth Icon" width={50} />
        <img src={icon5} alt="Gender Equality Icon" width={50} />
        <img src={icon6} alt="Grocery Icon" width={50} />
        <img src={icon7} alt="Healthcare Icon" width={50} />
        <img src={icon8} alt="Inequality Icon" width={50} />
        <img src={icon9} alt="Infrastructure Icon" width={50} />
        <img src={icon10} alt="Education Icon" width={50} />
        <img src={icon11} alt="Renewable Energy Icon" width={50} />
        <img src={icon12} alt="Sustainability Icon" width={50} />
      </div>
      <div className="w-full h-[2px] mb-[10px] bg-[#171443] my-[50px]"></div>
      <div className="w-full max-w-[1100px] my-[50px] flex items-center justify-center flex-col">
        <h1
          id="mission-section"
          className="uppercase mb-[40px] text-[30px] sm:text-[40px] font-semibold text-[#171443] text-center"
        >
          One Question Endures: How Do We Do It?
        </h1>
        <p className="text-center text-[#171443] text-sm sm:text-base">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus
          perspiciatis nobis, numquam laboriosam voluptatem ipsam eum modi
          deserunt blanditiis perferendis consequuntur a, culpa hic id aut
          labore consequatur. Consequatur quam harum doloremque placeat sint sit
          architecto voluptate sapiente atque nemo ex veniam natus, facilis
          quidem blanditiis possimus similique. Nemo, vel. Dicta velit dolores
          eveniet, qui consequuntur eaque optio reprehenderit nemo
          necessitatibus, sunt saepe vel eum mollitia facere, incidunt alias
          temporibus eius placeat. Maxime esse soluta excepturi fuga recusandae
          perspiciatis officiis perferendis magnam beatae illo? Expedita,
          soluta. Deserunt.
        </p>
      </div>
      <div className="w-full h-[350px]">
        <img
          src={banner2}
          alt="Banner "
          className="object-contain w-full h-[350px] sm:h-[400px] md:h-[450px]"
        />
      </div>
      <div className="w-full max-w-[1100px] mt-[50px] flex items-center justify-center flex-col">
        <h1
          id="approch-section"
          className="uppercase mb-[40px] text-[30px] sm:text-[40px] font-semibold text-[#171443] text-center"
        >
          Creating A Society Where It's Easy To Take Action
        </h1>
        <p className="text-center text-[#171443] text-sm sm:text-base">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus
          perspiciatis nobis, numquam laboriosam voluptatem ipsam eum modi
          deserunt blanditiis perferendis consequuntur a, culpa hic id aut
          labore consequatur. perspiciatis nobis, numquam laboriosam voluptatem
          ipsam eum modi deserunt blanditiis perferendis consequuntur a, culpa
          hic id aut labore consequatur.
        </p>
      </div>
    </div>
  );
};

export default Mission;
