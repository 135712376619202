import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Contacts from './Pages/Contacts/Contacts';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import Navbar from './Components/Navbar';
// import Mission from './Pages/Mission/Mission';
// import WhatWeDo from './Pages/WhatWeDo/WhatWeDo';
// import WhoWeServe from './Pages/WhoWeServe/WhoWeServe';
// import GetInvolved from './Pages/GetInvolved/GetInvolved';
import Resources from './Pages/Resources/Resources';
import Donate from './Pages/Donate/Donate';
import About from './Pages/About/About';
import Blog from './Pages/Blog/Blog';
import Advisors from './Pages/Advisors/Advisors';
import Career from './Pages/Career/Career';
import Footer from './Components/Footer';
// import ActivityHistory from './Pages/ActivityHistory/ActivityHistory';
import Projects from './Pages/Projects/Projects';
import WhoWeServe from './Pages/WhoWeServe/WhoWeServe';
import JoinUs from './Pages/JoinUs/JoinUs';
import OurTeam from './Pages/OurTeam/OurTeam';
import Mission from './Pages/Mission/Mission';
import Vision from './Pages/Vision/Vision';
import CoreValues from './Pages/CoreValues/CoreValues';
import Donors from './Pages/Donors/Donors';
import Ongoing from './Pages/Ongoing/Ongoing';
import Completed from './Pages/Completed/Completed';
// import WhoWeAre from './Components/WhoWeAre';

function App() {
  return (
    <>
      <Router>
        <>
          <Navbar />
          <Routes>
            <Route exact path='/' element={<Home />} />
            {/* <Route path='/who-we-are/:id' element={<WhoWeAre />}></Route> */}
            {/* <Route path='/our-mission' element={<Mission />} /> */}
            {/* <Route path='/what-we-do' element={<WhatWeDo />} /> */}
            {/* <Route path='/activity-history' element={<ActivityHistory />} /> */}
            <Route path='/who-we-serve' element={<WhoWeServe />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/projects/ongoing' element={<Ongoing />} />
            <Route path='/projects/completed' element={<Completed />} />


            {/* <Route path='/get-involved' element={<GetInvolved />} /> */}
            <Route path='/join-us' element={<JoinUs />} />
            <Route path='/resources' element={<Resources />} />
            <Route path='/advisor-panel' element={<Advisors />} />
            <Route path='/donate' element={<Donate />} />

            <Route path='/about-us' element={<About />} />
            <Route path='/about-us/our-team' element={<OurTeam />} />
            <Route path='/about-us/mission' element={<Mission />} />
            <Route path='/about-us/vision' element={<Vision />} />
            <Route path='/about-us/core-values' element={<CoreValues />} />
            <Route path='/about-us/donors' element={<Donors />} />


            <Route path='/contact' element={<Contacts />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/career' element={<Career />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
          <Footer />
        </>
      </Router>
    </>
  );
}

export default App;
