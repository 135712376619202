import React from "react";
import TeamCard from "../../Components/Cards/TeamCard";
import testImage from "../../images/team_1.jpg";
const teamData = [
  {
    id: 1,
    title: "Title 1",
    desc: "desc 1",
    img: testImage,
  },
  {
    id: 2,
    title: "Title 2",
    desc: "desc 2",
    img: testImage,
  },
  {
    id: 3,
    title: "Title 3",
    desc: "desc 3",
    img: testImage,
  },
  {
    id: 4,
    title: "Title 4",
    desc: "desc 4",
    img: testImage,
  },
  {
    id: 5,
    title: "Title 5",
    desc: "desc 5",
    img: testImage,
  },
  {
    id: 6,
    title: "Title 5",
    desc: "desc 5",
    img: testImage,
  },
  {
    id: 7,
    title: "Title 5",
    desc: "desc 5",
    img: testImage,
  },
  {
    id: 8,
    title: "Title 5",
    desc: "desc 5",
    img: testImage,
  },
];

const OurTeam = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Governing Body */}
      <div className="mb-16">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-4 uppercase">
          Governing Body
        </h2>
        <p className="text-gray-600 text-center max-w-3xl mx-auto mb-8">
          Our Governing Body guides Dreamers Bangladesh with a shared vision and
          dedication to impact. Comprising passionate leaders, it oversees our
          programs, ensuring transparency, integrity, and sustainable growth in
          all we do. The Governing Body is committed to making a difference by
          steering us towards a brighter future for the Youths of the country.
        </p>
        <div className="">
          <TeamCard teamData={teamData} />
        </div>
      </div>

      {/* Advisory Panel */}
      <div className="mb-16">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-4 uppercase">
          Advisory Panel
        </h2>
        <p className="text-gray-600 text-center max-w-3xl mx-auto mb-8">
          Our Advisory Panel includes esteemed experts who provide guidance and
          strategic insight to strengthen Dreamers Bangladesh’s mission. These
          advisors bring wisdom, experience, and a fresh perspective to our
          work, helping us grow and make a meaningful impact. Meet the
          visionaries who guide our journey!
        </p>
        <div className="">
          <TeamCard teamData={teamData} />
        </div>
      </div>

      {/* Senior Cabinet */}
      <div className="mb-16">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-4 uppercase">
          Senior Cabinet
        </h2>
        <p className="text-gray-600 text-center max-w-3xl mx-auto mb-8">
          Our Senior Cabinet brings together experienced mentors from diverse
          fields, each committed to guiding and empowering the next generation
          of Dreamers. These leaders drive the development of each department,
          working hand-in-hand with dedicated youth to create lasting impact and
          growth. Explore to meet the mentors shaping our future.
        </p>
        <div className="">
          <TeamCard teamData={teamData} />
        </div>
      </div>

      {/* Junior Cabinet */}
      <div>
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-4 uppercase">
          Junior Cabinet
        </h2>
        <p className="text-gray-600 text-center max-w-3xl mx-auto mb-8">
          Meet the passionate young Dreamers from universities, colleges, and
          schools who are driving our mission forward. The Junior Cabinet
          consists of dedicated youth leaders working alongside us to empower
          and uplift students across diverse educational institutions. Discover
          the future leaders shaping our journey!
        </p>
        <div className="">
          <TeamCard teamData={teamData} />
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
