// import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import openn from "../icons/open.png";
// import close from "../icons/close.png";

// const Menu = () => {
//   const [open, setOpen] = useState(false);

//   const NavLinks = [
//     { id: 1, title: "Home", url: "/" },
//     { id: 3, title: "Who We Serve", url: "/who-we-serve" },
//     { id: 4, title: "Projects", url: "/projects" },
//     { id: 5, title: "About", url: "/about-us" },
//     { id: 6, title: "Join Us", url: "/join-us" },
//     { id: 7, title: "Donate", url: "/donate" },
//     { id: 8, title: "Contact", url: "/contact" },
//     { id: 9, title: "Blog", url: "/blog" },
//   ];

//   return (
//     <div>
//       {!open ? (
//         <img
//           src={openn}
//           alt="Open Menu"
//           width={24}
//           height={24}
//           onClick={() => setOpen(true)}
//         />
//       ) : (
//         <img
//           src={close}
//           alt="Close Menu"
//           width={24}
//           height={24}
//           onClick={() => setOpen(false)}
//         />
//       )}

//       {/* Mobile Menu Dropdown */}
//       {open && (
//         <div className="bg-white text-[#171443] absolute left-0 top-[60px] md:top-[100px] h-[calc(100vh-10rem)] md:h-[calc(100vh-100px)] w-full flex flex-col gap-[2px] pl-[10px] items-start justify-start text-lg z-30 overflow-y-auto uppercase">
//           {NavLinks.map((item) => (
//             <NavLink
//               key={item.id}
//               to={item.url}
//               className="hover:text-[#7083E1]"
//               onClick={() => setOpen(false)}
//             >
//               {item.title}
//             </NavLink>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Menu;
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import openn from "../icons/open.png";
import close from "../icons/close.png";
import arrowDown from "../icons/down-arrow.png";
import arrowUp from "../icons/up-arrow.png";

const Menu = () => {
  const [open, setOpen] = useState(false);
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [isProjectsDropdownOpen, setIsProjectsDropdownOpen] = useState(false);

  const NavLinks = [
    { id: 1, title: "Home", url: "/" },
    { id: 3, title: "Who We Serve", url: "/who-we-serve" },
    {
      id: 4,
      title: "Projects",
      url: "#", // Set URL to '#' or remove URL for the dropdown toggle
      dropdown: [
        { title: "Ongoing", url: "/projects/ongoing" },
        { title: "Completed", url: "/projects/completed" },
      ],
    },
    {
      id: 5,
      title: "About",
      url: "#", // Set URL to '#' or remove URL for the dropdown toggle
      dropdown: [
        { title: "Our Team", url: "/about-us/our-team" },
        { title: "Mission", url: "/about-us/mission" },
        { title: "Vision", url: "/about-us/vision" },
        { title: "Donors", url: "/about-us/donors" },
        { title: "Core Values", url: "/about-us/core-values" },
      ],
    },
    { id: 6, title: "Join Us", url: "/join-us" },
    { id: 7, title: "Donate", url: "/donate" },
    { id: 8, title: "Contact", url: "/contact" },
    { id: 9, title: "Blog", url: "/blog" },
  ];

  const toggleAboutDropdown = () => {
    setIsAboutDropdownOpen((prev) => !prev);
  };

  const toggleProjectsDropdown = () => {
    setIsProjectsDropdownOpen((prev) => !prev);
  };

  return (
    <div>
      {!open ? (
        <img
          src={openn}
          alt="Open Menu"
          width={24}
          height={24}
          onClick={() => setOpen(true)}
        />
      ) : (
        <img
          src={close}
          alt="Close Menu"
          width={24}
          height={24}
          onClick={() => setOpen(false)}
        />
      )}

      {/* Mobile Menu Dropdown */}
      {open && (
        <div className="bg-white text-[#171443] absolute left-0 top-[60px] md:top-[100px] h-[calc(100vh-10rem)] md:h-[calc(100vh-100px)] w-full flex flex-col gap-[4px] pl-[10px] items-start justify-start text-lg z-30 overflow-y-auto uppercase">
          {NavLinks.map((item) => (
            <div key={item.id}>
              {/* If item has no dropdown, render directly */}
              {!item.dropdown ? (
                <NavLink
                  to={item.url}
                  className="hover:text-[#7083E1] uppercase"
                  onClick={() => setOpen(false)}
                >
                  {item.title}
                </NavLink>
              ) : (
                <>
                  {/* Dropdown toggle button for Projects and About */}
                  <div className="flex justify-between items-center w-full">
                    <button
                      className="text-left w-full hover:text-[#7083E1] flex items-center uppercase"
                      onClick={() => {
                        if (item.id === 4) toggleProjectsDropdown();
                        if (item.id === 5) toggleAboutDropdown();
                      }}
                    >
                      <span>{item.title}</span>
                      <img
                        src={
                          (item.id === 4 && isProjectsDropdownOpen) ||
                          (item.id === 5 && isAboutDropdownOpen)
                            ? arrowUp
                            : arrowDown
                        }
                        alt="Dropdown Arrow"
                        width={14}
                        className="ml-2" // Add margin to separate the icon
                      />
                    </button>
                  </div>

                  {/* Dropdown links */}
                  {(isAboutDropdownOpen && item.id === 5) ||
                  (isProjectsDropdownOpen && item.id === 4) ? (
                    <div className="flex flex-col gap-2 ml-4">
                      {item.dropdown.map((subItem) => (
                        <NavLink
                          key={subItem.url}
                          to={subItem.url}
                          className="hover:text-[#7083E1] uppercase"
                          onClick={() => setOpen(false)}
                        >
                          {subItem.title}
                        </NavLink>
                      ))}
                    </div>
                  ) : null}
                </>
              )}
            </div>
          ))}

          {/* Donate button */}
          <NavLink
            to="/donate"
            className="px-4 py-2 bg-[#27a00e] hover:bg-[#70d580] text-white rounded-lg mt-2"
            onClick={() => setOpen(false)}
          >
            Donate
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Menu;
