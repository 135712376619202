import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import logo2 from "../images/logo_2.png";
import Menu from "./Menu";
import arrowDown from "../icons/down-arrow.png";
import arrowUp from "../icons/up-arrow.png";

const Navbar = () => {
  const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
  const [isProjecstDropdownOpen, setIsProjectsDropdownOpen] = useState(false);
  const aboutDropdownRef = useRef(null);
  const projectsDropdownRef = useRef(null);

  const toggleAboutDropdown = () => {
    setIsAboutDropdownOpen((prev) => !prev);
  };

  const toggleProjectsDropdown = () => {
    setIsProjectsDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      aboutDropdownRef.current &&
      !aboutDropdownRef.current.contains(event.target)
    ) {
      setIsAboutDropdownOpen(false);
    }
    if (
      projectsDropdownRef.current &&
      !projectsDropdownRef.current.contains(event.target)
    ) {
      setIsProjectsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="h-[60px] md:h-[100px] border-b-[#7083E1] border-b-[1px] flex justify-between items-center text-[#171443] sticky top-0 z-30 bg-white px-4 md:px-10 uppercase">
      <div className="flex items-center">
        {/* Logo */}
        <img
          src={logo2}
          alt="Logo"
          className="h-[50px] md:h-[80px] object-cover"
        />

        {/* Brand Name */}
        <NavLink
          to="/"
          className="ml-4 text-base md:font-semibold flex items-center"
        >
          <div className="flex flex-col">
            <span className="text-lg md:text-[24px]">Dreamers</span> Bangladesh
          </div>
        </NavLink>
      </div>

      {/* Desktop Navigation */}
      <div className="hidden md:flex items-center justify-center gap-6">
        <NavLink className="hover:text-[#7083E1]" to="/">
          Home
        </NavLink>
        {/* <NavLink className="hover:text-[#7083E1]" to="/our-mission">
          Our Mission
        </NavLink> */}
        {/* <NavLink className="hover:text-[#7083E1]" to="/activity-history">
          Activity Histroy
        </NavLink> */}
        <NavLink className="hover:text-[#7083E1]" to="/who-we-serve">
          Who We Serve
        </NavLink>
        {/* <NavLink className="hover:text-[#7083E1]" to="/projects">
          Projects
        </NavLink> */}
        <div className="relative" ref={projectsDropdownRef}>
          <button
            className="hover:text-[#7083E1] focus:outline-none uppercase"
            onClick={toggleProjectsDropdown}
          >
            <div className="flex gap-1 justify-center items-center">
              <div>Projects</div>
              {isProjecstDropdownOpen ? (
                <img src={arrowUp} alt="" width={14} />
              ) : (
                <img src={arrowDown} alt="" width={14} />
              )}
            </div>
          </button>
          {isProjecstDropdownOpen && (
            <div className="absolute top-full left-[-15px] bg-white shadow-2xl rounded-[2px] w-40">
              <NavLink
                to="/projects/ongoing"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={toggleProjectsDropdown}
              >
                Ongoing
              </NavLink>
              <NavLink
                to="/projects/completed"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={toggleProjectsDropdown}
              >
                Completed
              </NavLink>
            </div>
          )}
        </div>
        {/* <NavLink className="hover:text-[#7083E1]" to="/about-us">
          About
        </NavLink> */}
        {/* About Dropdown */}
        <div className="relative" ref={aboutDropdownRef}>
          <button
            className="hover:text-[#7083E1] focus:outline-none uppercase"
            onClick={toggleAboutDropdown}
          >
            <div className="flex gap-1 justify-center items-center">
              <div>About</div>
              {isAboutDropdownOpen ? (
                <img src={arrowUp} alt="" width={14} />
              ) : (
                <img src={arrowDown} alt="" width={14} />
              )}
            </div>
          </button>
          {isAboutDropdownOpen && (
            <div className="absolute top-full left-[-15px] bg-white border-gray-300 shadow-2xl rounded-[2px] w-40">
              <NavLink
                to="/about-us/our-team"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={toggleAboutDropdown}
              >
                Our Team
              </NavLink>
              <NavLink
                to="/about-us/mission"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={toggleAboutDropdown}
              >
                Mission
              </NavLink>
              <NavLink
                to="/about-us/vision"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={toggleAboutDropdown}
              >
                Vision
              </NavLink>
              <NavLink
                to="/about-us/donors"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={toggleAboutDropdown}
              >
                Donors
              </NavLink>
              <NavLink
                to="/about-us/core-values"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={toggleAboutDropdown}
              >
                Core Values
              </NavLink>
            </div>
          )}
        </div>
        {/* <NavLink className="hover:text-[#7083E1]" to="/get-involved">
          Get Involved
        </NavLink> */}
        <NavLink className="hover:text-[#7083E1]" to="/join-us">
          Join Us
        </NavLink>
        <NavLink
          to="/donate"
          className="px-4 py-2 bg-[#27a00e] hover:bg-[#70d580] text-white rounded-lg"
        >
          Donate
        </NavLink>

        {/* Extra links */}
        <div className="flex items-center justify-center gap-4 text-sm">
          <NavLink className="hover:text-[#7083E1]" to="/contact">
            Contact
          </NavLink>
          <NavLink className="hover:text-[#7083E1]" to="/blog">
            Blog
          </NavLink>
          {/* <NavLink className="hover:text-[#7083E1]" to="/career">
            Career
          </NavLink> */}
        </div>
      </div>

      {/* Mobile Menu (Hamburger Icon) */}
      <div className="md:hidden">
        <Menu />
      </div>
    </div>
  );
};

export default Navbar;
