import React from "react";
import "./TeamCard.css";

const TeamCard = ({ teamData }) => {
  return (
    <div className="team-card-wrapper">
      {teamData.map((member) => (
        <div className="card-container" key={member.id}>
          <div className="curtain"></div>
          <div className="card-content">
            <img src={member.img} alt={member.title} className="card-image" />
            <div className="text-content">
              <h3 className="card-title">{member.title}</h3>
              <p className="card-desc">{member.desc}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamCard;
